import * as React from "react"
import Layout from "../components/layout"
import Spacer from "../components/spacer"
import Title from "../components/title"
import Blog from "../components/blog"
import Seo from "../components/seo"
import { graphql } from "gatsby" 

const About = ({data}) => {
  var page = data.page;
  return (
  <Layout header='dark'>
    <Spacer />
    <div className='pth m-show'/>
    <Title title={page.newsTitle} subtitle={page.newsSubtitle} hideButton={true}/>
    <Blog articles={data.news.edges} />
  </Layout>
)
}

export const Head = () => <Seo title="Blog | Foster Burton" />

export default About

export const query = graphql`
  query AboutPage {
    page:datoCmsHomepage {
      newsTitle
      newsSubtitle
    }
    news:allDatoCmsNew(sort: {fields: meta___createdAt, order: DESC}) {
      edges {
        node {
          title
          link
          readTime
          featureImage {
            gatsbyImageData
          }
          button
          meta {
            publishedAt(formatString: "D MMMM YYYY")
          }
        }
      }
    }
  }
`