import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const Blog = ({articles}) => {
  return (
  <div className='p40 pt0'>
    {articles?.map((article, index) => { 
      article = article.node;
      return (
        <div className='flex m-wrap m-col-reverse mb40 bb1 pb40'>
          <div className='w-30 m-100'>
            <p className='m0 mt40 m-mt20 uppercase'>{article.meta.publishedAt}</p>
            <p className='m0 h2 max-300 m-max-100 mt40 m-mb20 m-mt20 mb40'>{article.title}</p>
            <p className='m0 mb40 m-mb20 uppercase'>{article.readTime}</p>
            {(article.link ?
              <a href={article.link} target='_blank' className='button m0'>{(article.button ? article.button:'Read More')}</a> :''
            )}
          </div>
          <div className='w-70 m-100'>
            <div className='ratio-2-3 bg-grey pos-rel'>
              <GatsbyImage className='bg-image' image={article.featureImage?.gatsbyImageData} alt={article.title} />
            </div>
          </div>
        </div>
      )
    })}
  </div>
  )
}

export default Blog
